<template>
  <div>
    <fieldset>
        <div class="form-card">
            <div class="row">
                <div class="col-7">
                    <h2 class="fs-title">{{stepText}}</h2>
                </div>
                <div class="col-5">
                    <h2 class="steps">ধাপ {{step}} - {{maxStep}}</h2>
                </div>
            </div>
            <div v-if="!captchaToken" class="captcha-container">
              <vue-hcaptcha
                sitekey="8c72efd5-2d44-4d1b-8b40-17a82f0f682a"
                @verify="verifyCaptcha"
              />
            </div>
            <div v-if="captchaToken" class="file-upload-container">
              <div class="upload-box">
                <file-select
                  :isRequired="true"
                  :fileNumber="1"
                  :uploadUrl="uploadUrl1"
                  @fileUpload="getFileUrl"
                  @fileRemove="removeFile"
                />
                <textarea
                  v-if="$store.state.video.file1"
                  type="text"
                  v-model="$store.state.video.file1.description"
                  placeholder="প্রথম ছবির বিবরণ (সর্বোচ্চ ২০০ শব্দ) *"
                />
              </div>
              <div class="upload-box">
                <file-select
                  :fileNumber="2"
                  :uploadUrl="uploadUrl2"
                  :disabled="!$store.state.video.file1"
                  @fileUpload="getFileUrl"
                  @fileRemove="removeFile"
                />
                <textarea
                  v-if="$store.state.video.file2"
                  type="text"
                  v-model="$store.state.video.file2.description"
                  placeholder="দ্বিতীয় ছবির বিবরণ (সর্বোচ্চ ২০০ শব্দ) *"
                />
              </div>
              <div class="upload-box">
                <file-select
                  :fileNumber="3"
                  :uploadUrl="uploadUrl3"
                  :disabled="!$store.state.video.file2"
                  @fileUpload="getFileUrl"
                  @fileRemove="removeFile"
                />
                <textarea
                  v-if="$store.state.video.file3"
                  type="text"
                  v-model="$store.state.video.file3.description"
                  placeholder="তৃতীয় ছবির বিবরণ (সর্বোচ্চ ২০০ শব্দ) *"
                />
              </div>
              <div class="upload-box">
                <file-select
                  :fileNumber="4"
                  :uploadUrl="uploadUrl4"
                  :disabled="!$store.state.video.file3"
                  @fileUpload="getFileUrl"
                  @fileRemove="removeFile"
                />
                <textarea
                  v-if="$store.state.video.file4"
                  type="text"
                  v-model="$store.state.video.file4.description"
                  placeholder="চতুর্থ ছবির বিবরণ (সর্বোচ্চ ২০০ শব্দ) *"
                />
              </div>
              <div class="upload-box">
                <file-select
                  :fileNumber="5"
                  :uploadUrl="uploadUrl5"
                  :disabled="!$store.state.video.file4"
                  @fileUpload="getFileUrl"
                  @fileRemove="removeFile"
                />
                <textarea
                  v-if="$store.state.video.file5"
                  type="text"
                  v-model="$store.state.video.file5.description"
                  placeholder="পঞ্চম ছবির বিবরণ (সর্বোচ্চ ২০০ শব্দ) *"
                />
              </div>
            </div>
        </div>
    </fieldset>
  </div>
</template>

<script>
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
import FileSelect from '@/components/registration/FileSelect.vue';

export default {
  data: () => ({
    uploadUrl1: '',
    uploadUrl2: '',
    uploadUrl3: '',
    uploadUrl4: '',
    uploadUrl5: '',
    maxFiles: 5,
    captchaToken: '',
  }),
  components: {
    FileSelect,
    VueHcaptcha,
  },
  props: {
    step: {
      type: Number,
      default: 2,
    },
    maxStep: {
      type: Number,
      default: 3,
    },
    stepText: {
      type: String,
      default: 'আপনার তথ্য',
    },
  },
  methods: {
    async getUploadUrl() {
      try {
        const config = {
          method: 'post',
          url: `${process.env.VUE_APP_API_ENDPOINT}/campaign/getUploadSignedUrl`,
          headers: {
            'Content-Type': 'application/json',
          },
          data: {
            token: this.captchaToken,
          },
        };

        const { data } = await this.axios(config);

        this.uploadUrl1 = data.url1;
        this.uploadUrl2 = data.url2;
        this.uploadUrl3 = data.url3;
        this.uploadUrl4 = data.url4;
        this.uploadUrl5 = data.url5;
      } catch (err) {
        console.error(err);
      }
    },
    getFileUrl({ url, fileNumber }) {
      this.$store.state.video[`file${fileNumber}`] = { url, description: '' };
    },
    removeFile({ fileNumber }) {
      this.$store.state.video[`file${fileNumber}`] = null;
    },
    verifyCaptcha(token) {
      this.captchaToken = token;
      this.getUploadUrl();
    },
  },
};
</script>

<style lang="stylus" scoped>
.hidden
  display none
.captcha-container
  display flex
  align-items center
  justify-content center
.upload-box
  position relative
  border 1px solid #ddd
  padding 1em
  margin-bottom 1em
.upload-box
  div
    position relative
</style>
