<template>
  <div class="event">
    <div class="inner-text">
      <h1 class="txt-red display-1 font-weight-bold event-head mrb">
        আপনিও হতে পারেন <br>
        সময় টিভির ফটো আইকন
      </h1>
      <div class="text-white submig-box mrb">বিশ্ব পর্যটন দিবস উপলক্ষে সময় টেলিভিশন এর উদ্যোগে
        ফটোগ্রাফি প্রতিযোগিতায় অংশগ্রহণ করুন।</div>
      <div class="r-button text-white submig-box mb-4">
        <span class=""><img
          width="40"
          src="@/assets/deadline-icon.png"
          alt="deadline icon">
          আবেদনের শেষ তারিখ: ২২ সেপ্টেম্বর, ২০২২</span>.
      </div>
    </div>
    <a v-scroll-to="{el: '#registration',offset: -90}"
    class="btn-contact-lg mrb" href="#registration">আবেদন করুন</a>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="stylus">
.event
  position absolute
  top 90px
  width 100%
  padding 100px 10%
.event-head
  font-size 40px
  font-weight bold
  text-transform uppercase
.btn-contact-lg
  font-size 150%
  color #fff !important
  background-color #ff0000 !important
  border-color #ff0000 !important
  padding 20px 30px 20px 30px
  border-radius 30px
  text-transform uppercase
  display inline-block
  margin-top 20px
.submig-box
  font-size 30px
@media screen and (max-width: 766px)
  .event
    top 100px
    width 100%
    padding 0 5%
  .event-head
    margin-top 20px
    font-size 50px
    font-weight bold
    margin-bottom 0
  .btn-contact-lg
    margin-top  0
    font-size 18px
    padding 5px 10px
    border-radius 20px
  .submig-box
    font-size 20px
@media screen and (max-width: 575px)
  .event
    top 50px
    width 100%
    padding 0 5%
  .event-head
    margin-top 0
    font-size 25px
    font-weight bold
    margin-bottom 0
  .btn-contact-lg
    margin-top  0
    font-size 20px
    padding 5px 10px
    border-radius 20px
  .submig-box
    font-size 15px
  .mrb
    display none
  .r-button
    display none
@media screen and (max-width: 400px)
  .event
    top 40px
    width 100%
    padding 0 5%
  .event-head
    margin-top 20px
    font-size 18px
    font-weight normal
    margin-bottom 0
  .btn-contact-lg
    margin-top 0
    font-size 18px
    padding 5px 10px
    border-radius 20px
</style>
