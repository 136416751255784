<template>
  <div>
    <div id="faq" class="faq">
        <div class="container">
        <h2 class="text-center faq-head">
            FAQ
        </h2>
        <h4 class="text-center text-white">find the frequently asked questions</h4>
        <div class="row">
            <div class="col">
                <template v-for="(faq, i) in faqs">
                    <button
                        :key="i"
                        :class="{ 'accordion': true, 'active': i === selectedIndex }"
                        @click="selectedIndex = i"
                    >
                        <span class="rounded-btn">{{i+1}}</span> {{faq.name}}
                    </button>
                    <div
                        :key="'i'+i"
                        :class="{ 'panel': true, 'active': i === selectedIndex }"
                    >
                        <p class="pt-2">{{faq.description}}</p>
                        <ul>
                            <li
                              v-for="(detail,j) in faq.details" :key="'j'+j">
                              {{detail}}
                            </li>
                        </ul>
                        <p></p>
                    </div>
                </template>
            </div>
        </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    selectedIndex: 0,
    faqs: [
      {
        name: 'Eligibility',
        details: [
          'Anyone can enter the event, except for staff, directors, and employees of Somoy TV, and their respective spouses, parents, siblings, and children.',
          'This is a national event. Any individual holding the legal citizenship of Bangladesh can participate in this event.',
          'Team submission is strictly prohibited from this event',
        ],
      },
      {
        name: 'Language, Length and Format',
        details: [
          'The description should either be in English or Bengali.',
          'Photo should be minimum Maximum 100MB',
          'Picture size should be 16:9 and 4:3',
        ],
      },
      {
        name: 'Submission Rules',
        description: 'Your video entry should be uploaded to this website after you register yourself with the event. Before submitting the video you are strongly requested to give the following information upon registering:',
        details: [
          'A description within 200 words what the picture is trying to show and the theme of your submitted picture',
          'An email address and active, proper contact information where we can contact you.',
        ],
      },
      {
        name: 'Prohibited facts of a picture',
        details: [
          'Material that violates or infringes on another’s rights including, but not limited to, privacy, publicity, and intellectual property;',
          'A focus on brand names or trademarks;',
          'Copyrighted material without necessary authorizations;',
          'Material that is inappropriate, indecent, obscene, hateful, defamatory, slanderous, or libelous;',
          'Material that promotes racism, hatred, or harms against any group or individual or promotes discrimination based on race, sex, religion, nationality, disability, sexual orientation, or age;',
          'Material that is unlawful, in violation of, or contrary to the laws or regulations of the participant’s country.',
        ],
      },
      {
        name: 'Rounds details',
        details: [
          'Round 01: Registration >> Photo Submission >> Initial Screaning',
          'Round 02: Shortlist of 10 photos from all categories  ',
          'Final: Judges will select THREE winners',
        ],
      },
      {
        name: 'Winner Notification and Announcement',
        description: 'Winners, as determined by the authorities of Somoy TV, will be notified through e-mail, and will be required to respond (as directed) to the notification attempt. The failure to respond timely to the notification may result in elimination from the event.',
      },
      {
        name: 'Judges Panel',
        details: [
          'Round 01– Yet to decide',
          'Round 02- Yet to decide',
          'Round 03- Yet to decide',
        ],
      },
      {
        name: 'Copyright & Other rules',
        details: [
          'Somoy TV, sponsors, and those acting with its authority shall have the unrestricted, absolute, perpetual, worldwide right and license to use participant’s name, address, photograph, likeness, voice, biographical and personal background information, statements, and event entry upon communicating with the selected participants for the further steps.',
          'Somoy TV holds the authority to change, modify, disqualifying any candidates with proper reasoning, and cancellation the event at any time.',
          'By participating in the event, you hereby represent and warrant that you have read these Rules and are fully familiar with the necessary regulations to participate in the contest.',
        ],
      },
    ],
  }),
};
</script>

<style lang="stylus" scoped>
.faq
  color #fff
  background #121213
button.accordion
  border-top 1px solid #ff0000
  margin-top 10px
  font-size 20px
  font-weight normal
  font-stretch normal
  font-style normal
  line-height 2
  letter-spacing normal
  text-align left
  color #ffffff
.panel ul li
  color #a1a1a1
  list-style-type none
  padding-left 20px
.panel.active
    display block
.faq-head
  margin 0 auto
  font-size 95px
  font-weight 800
  font-stretch normal
  font-style normal
  line-height 0.42
  letter-spacing normal
  text-align left;
  color #ffffff
  padding 60px
.rounded-btn
  padding 5px 10px
  border-radius 50%
  background #ff0000
  margin-right 15px
  color #fff
@media screen and (max-width: 600px)
  .faq-head
    margin 0 auto
    font-size 25px
    padding-top 50px
</style>
