<template>
  <div id="home">
    <video-player />
    <overview />
    <faq v-if="false" />
    <timeline />
    <category />
    <rewards />
    <sponsor v-if="false" />
    <terms />
    <registration v-if="false" />
    <div class="sharethis-inline-share-buttons"></div>
    <bottom-bar />
    <mobile-menu v-if="$store.state.layout.isMobileMenuVisible" />
  </div>
</template>

<script>
import Category from '@/components/Category.vue';
import VideoPlayer from '@/components/VideoPlayer.vue';
import Overview from '@/components/Overview.vue';
import Faq from '@/components/Faq.vue';
import Sponsor from '@/components/Sponsor.vue';
import Registration from '@/components/Registration.vue';
import Rewards from '@/components/Rewards.vue';
import Terms from '@/components/Terms.vue';
import BottomBar from '@/components/BottomBar.vue';
import MobileMenu from '@/components/MobileMenu.vue';
import Timeline from '@/components/Timeline.vue';

export default {
  name: 'Home',
  components: {
    Category,
    Overview,
    Faq,
    Sponsor,
    Registration,
    BottomBar,
    VideoPlayer,
    MobileMenu,
    Rewards,
    Terms,
    Timeline,
  },
};
</script>

<style lang="stylus">
.sharethis-inline-share-buttons
  border-top 1px solid #ddd
  padding 50px
.sharethis-inline-share-buttons .st-btn
  margin-bottom 10px
.txt-red
  color red
</style>
