<template>
  <div class="mobile-menu">
    <span @click="$store.commit('updateMobileMenuVisible',false)">x</span>
    <a
        v-scroll-to="{el: '#home'}"
        href="#home"
        @click="$store.commit('updateMobileMenuVisible',false)"
    >
    HOME
    </a>
    <a
        v-scroll-to="{el: '#overview',offset: -100}" href="#overview"
        @click="$store.commit('updateMobileMenuVisible',false)">OVERVIEW</a>
    <a v-scroll-to="{el: '#faq',offset: -200}" href="#faq"
        @click="$store.commit('updateMobileMenuVisible',false)">FAQ</a>
    <a v-scroll-to="'#registration'" href="#registration"
        @click="$store.commit('updateMobileMenuVisible',false)">REGISTER</a>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="stylus" scoped>
.mobile-menu
  position fixed
  top 50px
  right 0
  display block
  background #ddd
  width 100%
  padding-right 20px
.mobile-menu span
    background #f00
    padding 2px 13px 6px 12px
    border-radius 50%
    color #fff
    position absolute
    left 10px
    top 10px
.mobile-menu a
  display block
  text-align right
  padding 5px 10px
@media screen and (max-width: 575px)
  .mobile-menu
    display block
</style>
