<template>
  <div>
    <fieldset>
        <div class="form-card">
            <div class="row">
                <div class="col-7">
                    <h2 class="fs-title">{{stepText}}</h2>
                </div>
                <div class="col-5">
                    <h2 class="steps">ধাপ {{step}} - {{maxStep}}</h2>
                </div>
            </div>
            <label class="fieldlabels">নাম: *</label>
            <input
              :class="{
                'error': error.isError && (error.name || !$store.state.details.name)
              }"
              type="text"
              v-model="$store.state.details.name"
              placeholder="আপনার নাম লিখুন"
            />
            <label class="fieldlabels">মোবাইল নম্বর: *</label>
            <input
              :class="{
                'error': error.isError && (error.phone || !$store.state.details.phone)
              }"
              type="text"
              v-model="$store.state.details.phone"
              placeholder="Mobile Number, eg: +8801XXXXXXXX"
            />
        </div>
    </fieldset>
  </div>
</template>

<script>
export default {
  props: {
    step: {
      type: Number,
      default: 1,
    },
    maxStep: {
      type: Number,
      default: 3,
    },
    stepText: {
      type: String,
      default: 'আপনার তথ্য',
    },
    error: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="stylus" scoped>

</style>
